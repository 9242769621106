
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import t from "@common/src/i18n/t";
import Template from "@/views/dashboard/template.vue";

const marketing = namespace("marketing");
const base = namespace("base");
@Component({
  components: { Template }
})
export default class ValueCardView extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action queryCardTemplateDetail;
  emptyToLine = emptyToLine;
  valueCardForm = {
    cardTemplateName: "",
    cardAmount: "",
    giftAmount: "",
    enableStatus: 1,
    checked: true,
    usefulLife: t("marketing.permanent"),
    validPeriod: -1,
    cardDesc: "",
    cardType: 2,
    cardTemplateCode: "",
    carryingAmount: "",
    usedStatus: undefined,
    storesList:[]
  };
  //计算属性
  get computeCountMoney() {
    return (Number(this.valueCardForm.cardAmount) + Number(this.valueCardForm.giftAmount)).toFixed(2);
  }
  get isAdd() {
    return this.$route.name === "ValueCardAdd";
  }
  get breadData() {
    return [
      { name: t("marketing.card-template"), path: "/marketing/card-template" },
      {
        name: t("v210831.stored-value-card")
      }
    ];
  }
  created() {
    this.queryCardTemplateDetail({ cardTemplateCode: this.$route.params.id }).then(res => {
      console.log("[ res ]", res);
      this.valueCardForm.cardAmount = res.data.cardAmount;
      this.valueCardForm.cardDesc = res.data.cardDesc;
      this.valueCardForm.cardTemplateCode = res.data.cardTemplateCode;
      this.valueCardForm.cardTemplateName = res.data.cardTemplateName;
      this.valueCardForm.carryingAmount = res.data.carryingAmount;
      this.valueCardForm.enableStatus = res.data.enableStatus;
      this.valueCardForm.giftAmount = res.data.giftAmount;
      this.valueCardForm.usedStatus = res.data.usedStatus;
      this.valueCardForm.storesList = res.data.storesList;
    });
  }
  mounted() {}
  goToEdit(id) {
    this.$router.push(`/marketing/value-card-edit/${this.$route.params.id}`);
  }
}
